import { CloudSharedProductionEnvironment } from "@smallstack/typesystem";
import { BackofficeEnvironment } from "./backoffice-environment";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: BackofficeEnvironment = {
  ...CloudSharedProductionEnvironment,
  production: true,
  hmr: false,
  azureAdClientId: "4c60d842-a4ab-4c1c-a098-46d71889bcf1",
  azureAdRedirectUrl: "https://cloud.smallstack.com"
};
